import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from '../../components/util-components/Loading';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page"/>}>
      <Switch>
        <Route path={`/auth/login`} component={lazy(() => import(`./login`))} />
        <Route path={`/auth/register`} component={lazy(() => import(`./register`))} />        
        <Route path={`/auth/forgot`} component={lazy(() => import(`./forgot`))} />
        <Route path={`/auth/reset-password`} component={lazy(() => import(`./resetpassword`))} />
        <Route path={`/auth/invited/:userId`} component={lazy(() => import(`./invited`))} />
        <Redirect from={`/auth`} to={`/auth/login`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;

