import React, { useState } from 'react';
import { Layout, Menu, Dropdown, Image, Select } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { QuestionCircleOutlined, UserOutlined, HomeOutlined, AlertOutlined, RocketOutlined, FunnelPlotOutlined, SettingOutlined, MailOutlined, ApartmentOutlined, LogoutOutlined, MailTwoTone, SecurityScanOutlined } from '@ant-design/icons';
import AppViews from '../views/app-views';
import { useAuth } from "../hooks/useAuth";
import CompanyDataService from '../services/company.service';


const { Header, Content } = Layout;

function AppLayout() {

  const { user, credential, logout } = useAuth();

  const items = [
    {
      label: (<Link to="/dashboard">Dashboard</Link>),
      key: 'home',
      
    },
    {
      label: (<Link to="/emails">Emails</Link>),
      key: 'emails',
    },
    {
      label: (<Link to="/work">Database Worksheet</Link>),
      key: 'work',
    },
    {
      label: (<Link to="/requested">Requested Companies</Link>),
      key: 'requests',
    },
    /*{
      label: (<Link to="/collections">My Emails</Link>),
      key: 'collections',
      
    },
    {
      label: (<Link to="/projects">Journey Collector </Link>),
      key: 'collector',
      icon: <FunnelPlotOutlined />
    },

    {
      label: (<Link to="/lists">Competitor Tracking</Link>),
      key: 'tracking',
            
    },
    {
      label: (<Link to="/emails">Browse</Link>),
      key: 'emails',
      
      children: [       
        {
          label: (<Link to="/emails">Emails</Link>),
          key: 'emails',
          icon: <MailOutlined />
        },
        {
          label: (<Link to="/brands">Brands</Link>),
          key: 'brands',
          icon: <ApartmentOutlined />
        },
        {
          label: (<Link to="/projects">Workflow Collector</Link>),
          key: 'projects',
          icon: <SecurityScanOutlined />
        }
      ],
    },*/

  ];

  const menuUser = (
    <Menu>
      {(() => {
        if (user && user.roles && user.roles.includes('admin')) {
          return <>
            <Menu.Item key="settings">
              <Link to="/settings">
                <SettingOutlined /> &nbsp; Settings
              </Link>
            </Menu.Item>
            <Menu.Item key="matcher">
              <Link to='/matcher'>
                <MailOutlined /> &nbsp; Email Matcher
              </Link>
            </Menu.Item>
            <Menu.Item key="work">
              <Link to='/work'>
                <MailOutlined /> &nbsp; Worksheet
              </Link>
            </Menu.Item>
            <Menu.Item key="manager">
              <Link to='/manager'>
                <ApartmentOutlined /> &nbsp; Company Manager
              </Link>
            </Menu.Item>
          </>
        }
      })()}
      {!user && (
        <>
          <Menu.Item key="register">
            <Link to='/auth/register'>
              <LogoutOutlined /> &nbsp; Register
            </Link>
          </Menu.Item>
          <Menu.Item key="login">
            <Link to='/auth/login'>
              <LogoutOutlined /> &nbsp; Login
            </Link>
          </Menu.Item>
          <Menu.Item key="forgot">
            <Link to='/auth/forgot'>
              <LogoutOutlined /> &nbsp; Forgot
            </Link>
          </Menu.Item>
        </>
      )}
      {user && (
        <>
          <Menu.Item key="logout" onClick={logout} >
            <Link to="/auth/login">
              <LogoutOutlined /> &nbsp; Logout
            </Link>
          </Menu.Item>
        </>
      )}
    </Menu>
  );


  const [current, setCurrent] = useState('home');
  const onClick = (e) => {
    //console.log('click ', e);
    setCurrent(e.key);
  };

  //Company Search Box in Menu
  const history = useHistory();

  async function fetchCompanySuggest(searchString) {
    return CompanyDataService.getList({
      nameSearch: searchString,
      "page": 1,
      "limit": 10,
      "sort": { 'field': 'created', 'order': '-1' },
      "sortd": "desc"
    }).then((response) => response.data).then((data) =>
      data.map((company) => ({
        label: company.name,
        value: company._id,
      })));

  };


  const SearchInput = (props) => {
    const [data, setData] = useState([]);
    const [value, setValue] = useState();
    const handleSearch = (newValue) => {
      fetchCompanySuggest(newValue).then((suggestions) => setData(suggestions));
    };
    const handleChange = (newValue) => {
      setValue('');
      history.push('/brand/' + newValue);
    };

    return (


      <Select
        showSearch
        value={value}
        placeholder='Search for a brand'
        style={props.style}
        defaultActiveFirstOption={true}
        suffixIcon={null}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        notFoundContent={null}
        options={(data || []).map((d) => ({
          value: d.value,
          label: d.label,
        }))}
      />
    );
  };

  return (

    <Layout>
      <Header style={{ background: '#fff', padding: 0 }}>
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <div style={{ margin: '0 24px', fontSize: '18px', fontWeight: 'bold' }}>
            <Link to='/dashboard'><Image src="/img/r1.png" width={145} preview={false} /></Link>
          </div>
          <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} style={{ flex: "1" }} />



          <div style={{ margin: '0 24px', fontSize: '18px', fontWeight: 'bold' }}>
            <SearchInput
              placeholder="input search text"
              style={{
                width: 200, fontSize: '.9rem'
              }}
            />


            <Dropdown overlay={menuUser} trigger={['click']} style={{ marginRight: "20px" }}>
              <a href="#" style={{ marginLeft: '16px' }}>
                <UserOutlined />
              </a>
            </Dropdown>
          </div>
        </div>
      </Header>
      <Content style={{ padding: '24px' }}>
        <AppViews />
      </Content>
    </Layout>

  );
}

export default AppLayout;