import Axios from 'axios';

const CompanyDataService = () => {
    return {
        getOne: function (_id) {
            return Axios.get('/api/companies/' + _id);
        },
        getList: function (queryParams) {
            return Axios.get('/api/companies/list', {
                params: queryParams
            });
        },
        getListBack: function (queryParams) {
            return Axios.get('/api/companies/listback', {
                params: queryParams
            });
        },
        getTags: function () {
            return Axios.get('/api/companies/tags');
        },
        getIndex: function () {
            return Axios.get('/api/companies/index');
        },
        getCompanyIndex: function (_id) {
            return Axios.get('/api/companies/' + _id + "/index");
        },
        update: function (crudObj) {
            return Axios.put('/api/companies/' + crudObj._id, crudObj);
        },
        create: function (crudObj) {
            return Axios.post('/api/companies', crudObj);
        },
        delete: function (_id) {
            return Axios.delete('/api/companies/' + _id);
        },
        getChanges: function (_id, queryParams) {
            return Axios.get('/api/companies/' + _id + "/changes", {
                params: queryParams
            });
        },
    }
}

export default CompanyDataService();