import React, { lazy, Suspense } from "react";
import { Provider } from "react-redux";
import { Route, Redirect, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from 'history'
import { store } from "./redux/store";
import AppLayout from "./layout/AppLayout";
import AppLayoutPublic from "./layout/AppLayoutPublic";
import AuthLayout from "./layout/AuthLayout";
import PrivateRoute from "./route/PrivateRoute";
import PublicRoute from "./route/PublicRoute";

import { ConfigProvider, theme } from "antd";


import Loading from './components/util-components/Loading';

import 'antd/dist/reset.css';

//Custom Style Imports
import "./styles/base.scss";
import "./styles/flex.scss";
import "./styles/helpers.scss";
import "./styles/codemirror.scss";


//const { Blue, Purple, Magenta, Red, Orange, Yellow } = RefineThemes;
const history = createBrowserHistory()


function App() {
  return (
    <ConfigProvider theme={{
      algorithm: theme.defaultAlgorithm,
    }}>
      <Provider store={store}>
        <Router history={history}>
          <Suspense fallback={<Loading cover="page" />}>
            <Switch>             
              <PublicRoute path="/email/:id">
                <AppLayoutPublic />
              </PublicRoute>              
              <PrivateRoute exact path="/">
                <Redirect to="/emails" />
              </PrivateRoute>
              <Route path="/auth">
                <AuthLayout />
              </Route>
              <PrivateRoute path="">
                <AppLayout />
              </PrivateRoute>
            </Switch>
          </Suspense>
        </Router>
      </Provider>
    </ConfigProvider>
  );
}
export default App;
