import { React } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { useAuth } from '../hooks/useAuth'



const PublicRoute = ({ children, ...rest }) => {
    const { user } = useAuth();

    console.log("PublicRoutePublicRoutePublicRoutePublicRoute");

    return (
        <Route
            {...rest}
            render={() => (children)}
        ></Route>
    );
};

export default PublicRoute;