import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from '../../components/util-components/Loading';

export const AppViewsPublic = () => {
  console.log("AppViewsPublic");
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route path={`/emails`} component={lazy(() => import(`./emails`))} />
        <Route path={`/email/:id`} component={lazy(() => import(`./email`))} ></Route>
        <Route path={`/dashboard`} component={lazy(() => import(`./dashboard`))} />
        <Route path={`/brands`} component={lazy(() => import(`./brands`))} />
        <Route path={`/brand/:id`} component={lazy(() => import(`./brand`))} />
        <Route path={`/lists`} component={lazy(() => import(`./lists`))} />
        <Route path={`/collections`} component={lazy(() => import(`./collections`))} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViewsPublic);