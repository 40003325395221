import React from "react";
import ReactDOM from "react-dom";


import { AuthProvider } from './hooks/useAuth';
import App from "./App";
import axios from 'axios';

//axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
axios.defaults.headers.post['Content-Type'] = 'application/json';




ReactDOM.render(
    <React.StrictMode>
        <AuthProvider>
            <App />
        </AuthProvider>
    </React.StrictMode>
    ,
    document.getElementById("root")
);
