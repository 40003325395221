import React, { useEffect, useState } from 'react';
import { Layout, Menu, Dropdown, Image, Select } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { UserOutlined, SettingOutlined, MailOutlined, ApartmentOutlined, LogoutOutlined } from '@ant-design/icons';
import AppViewsPublic from '../views/app-views/AppViewsPublic';
import { useAuth } from '../hooks/useAuth'
import CompanyDataService from '../services/company.service';


const { Header, Content } = Layout;

function AppLayoutPublic() {

  const { user, logout } = useAuth();


  const items = [
    {
      label: (<Link to="/dashboard">Dashboard</Link>),
      key: 'home'
    },
    {
      label: (<Link to="/projects">Journey Collector</Link>),
      key: 'collector'
    },
    {
      label: (<Link to="/collections">My Emails Public</Link>),
      key: 'SubMenu',
      children: [
        {
          label: 'Manage',
          key: 'manage'

        },
        {
          label: 'Import',
          key: 'import'

        },
        {
          label: 'Quick Upload',
          key: 'quick'

        },
      ],
    },
    {
      label: (<Link to="/lists">Competitor Research</Link>),
      key: 'research',
      children: [
        {
          label: (<Link to="/lists">My lists</Link>),
          key: 'lists'

        },
        {
          label: (<Link to="/emails">Browse emails</Link>),
          key: 'emails'

        },
        {
          label: (<Link to="/brands">Browse brands</Link>),
          key: 'brands'

        },
      ],
    },
  ];

  
  const menuUser = (
    <Menu>
      {(() => {
        if (user && user.roles && user.roles.includes('admin')) {
          return <>
            <Menu.Item key="settings">
              <Link to="/settings">
                <SettingOutlined /> &nbsp; Settings
              </Link>
            </Menu.Item>
            <Menu.Item key="matcher">
              <Link to='/matcher'>
                <MailOutlined /> &nbsp; Email Matcher
              </Link>
            </Menu.Item>
            <Menu.Item key="work">
              <Link to='/work'>
                <MailOutlined /> &nbsp; Worksheet
              </Link>
            </Menu.Item>
            <Menu.Item key="manager">
              <Link to='/manager'>
                <ApartmentOutlined /> &nbsp; Company Manager
              </Link>
            </Menu.Item>
          </>
        }
      })()}
      {!user && (
        <>
          <Menu.Item key="register">
            <Link to='/auth/register'>
              <LogoutOutlined /> &nbsp; Register
            </Link>
          </Menu.Item>
          <Menu.Item key="login">
            <Link to='/auth/login'>
              <LogoutOutlined /> &nbsp; Login
            </Link>
          </Menu.Item>
          <Menu.Item key="forgot">
            <Link to='/auth/forgot'>
              <LogoutOutlined /> &nbsp; Forgot
            </Link>
          </Menu.Item>
        </>
      )}
      {user && (
        <>
          <Menu.Item key="logout" onClick={logout} >
            <Link to="/auth/login">
              <LogoutOutlined /> &nbsp; Logout
            </Link>
          </Menu.Item>
        </>
      )}
    </Menu>
  );


  const [current, setCurrent] = useState('home');
  const onClick = (e) => {
    //console.log('click ', e);
    setCurrent(e.key);
  };

  //Company Search Box in Menu
  const history = useHistory();

  async function fetchCompanySuggest(searchString) {
    return CompanyDataService.getList({
      nameSearch: searchString,
      "page": 1,
      "limit": 10,
      "sort": { 'field': 'created', 'order': '-1' },
      "sortd": "desc"
    }).then((response) => response.data).then((data) =>
      data.map((company) => ({
        label: company.name,
        value: company._id,
      })));

  };


  const SearchInput = (props) => {
    const [data, setData] = useState([]);
    const [value, setValue] = useState();
    const handleSearch = (newValue) => {
      fetchCompanySuggest(newValue).then((suggestions) => setData(suggestions));
    };
    const handleChange = (newValue) => {
      setValue('');
      history.push('/brand/' + newValue);
    };

    return (


      <Select
        showSearch
        value={value}
        placeholder='Search for a brand'
        style={props.style}
        defaultActiveFirstOption={true}
        suffixIcon={null}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        notFoundContent={null}
        options={(data || []).map((d) => ({
          value: d.value,
          label: d.label,
        }))}
      />
    );
  };



  return (
    <Layout>
      <Header style={{ background: '#fff', padding: 0 }}>
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <div style={{ margin: '0 24px', fontSize: '18px', fontWeight: 'bold' }}>
            <Image src="/img/r1.png" width={145} preview={false} />
          </div>
          <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} style={{ flex: "1" }} />



          <div style={{ margin: '0 24px', fontSize: '18px', fontWeight: 'bold' }}>
            <SearchInput
              placeholder="input search text"
              style={{
                width: 200, fontSize: '.9rem'
              }}
            />
             <Dropdown overlay={menuUser} trigger={['click']} style={{ marginRight: "20px" }}>
              <a href="#" style={{ marginLeft: '16px' }}>
                <UserOutlined />
              </a>
            </Dropdown>


           
          </div>
        </div>
      </Header>
      <Content style={{ padding: '24px' }}>
        <AppViewsPublic />
      </Content>
    </Layout>

  );
}

export default AppLayoutPublic;