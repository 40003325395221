import { useState, useEffect, useContext, createContext } from 'react';
import firebaseConfig from '../config/FirebaseConfig';
import firebase from 'firebase/compat/app';
import  'firebase/compat/auth';
import 'firebase/compat/firestore';
import axios from 'axios';

//init firebase
firebase.initializeApp(firebaseConfig);

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

// Provider hook that creates auth object and handles state
export const AuthProvider = ({ children }) => {
  const [credential, setCredential] = useState(null);
  const [user, setUser] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const changePassword = ( oldpassword,  password) => {
      return new Promise((resolutionFunc,rejectionFunc) => {
          var cred = firebase.auth.EmailAuthProvider.credential( user.email, oldpassword );
          firebase.auth().currentUser.reauthenticateWithCredential(cred).then(()=>{
            firebase.auth().currentUser.updatePassword(password).then(()=>{
              resolutionFunc();
            });
          }).catch(()=>{
            rejectionFunc();
          });
      });
  };

  const setPref = ( key, val) => {
    let prefObj = localStorage.getItem("prefs_" + credential._id);
    if(prefObj){
      prefObj = JSON.parse(prefObj);
    }else{
      prefObj = {};
    }
    prefObj[key] = val;
    //console.log("SETprefObjprefObjprefObjprefObj", prefObj);
    localStorage.setItem( "prefs_" + credential._id, JSON.stringify(prefObj) );
  };


  const getAllPref = ( ) => {
    return localStorage.getItem("prefs_" + credential._id);
  };

  const getPref = ( key) => {
    let prefObj = localStorage.getItem("prefs_" + credential._id);
    if(prefObj){
      prefObj = JSON.parse(prefObj);
    }else{
      prefObj = {};
    }

    //console.log("GETprefObjprefObjprefObjprefObj", prefObj);

    return prefObj[key];
  };

  const linkEmailPassword = ( email, password) => {
    return new Promise((resolutionFunc,rejectionFunc) => {
        var cred = firebase.auth.EmailAuthProvider.credential( email, password );
  
        var promise = firebase.auth().currentUser.linkWithCredential(cred);
        
        promise.then((response) => {
          user.sign_in_providers.push('password');
          updateUser({sign_in_providers: user.sign_in_providers});
          resolutionFunc(response);
         // setCredential(response.user);
         // updateUser({sign_in_providers: });
        }).catch(err =>{
          rejectionFunc(err);
        });
    });
  };
  const updateUser = (values) => {
     return new Promise((resolutionFunc,rejectionFunc) => {
      firebase.auth().currentUser.updateProfile(values).then(() => {
        axios.put("/api/users/me", values).then((result) => {
          axios.get("/api/users/me").then((result) => {
            setUser(result.data);
            setIsAuthenticating(false);
            resolutionFunc();
          });
        });
      }).catch(()=>{
        rejectionFunc();
      });
    });
};

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const login = (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        setCredential(response.user);
        return response.user.getIdToken().then((idToken) => {
          return axios.post("/api/auth/tokenLogin", {'idToken': idToken}).then((result) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + result.data.token;
            return axios.get("/api/users/me").then((result) => {
              setUser(result.data);
              setIsAuthenticating(false);
            });
          });
        });
      });
  };

  const loginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider)
      .then((response) => {
        setCredential(response.user);
        console.log("google response", response);
        return response.user.getIdToken().then((idToken) => {
          return axios.post("/api/auth/signup", {'idToken': idToken}).then((result) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + result.data.token;
            return axios.get("/api/users/me").then((result) => {
              setUser(result.data);
              setIsAuthenticating(false);
            });
          });
        });
      });
  };

  const loginWithEmailLink = (email, code) => {
    return firebase.auth.signInWithEmailLink(email, code)
    .then(result=> {
      setCredential(result.user);
      return result.user.getIdToken().then((idToken) => {
        return axios.post("/api/auth/tokenLogin", {'idToken': idToken}).then((result) => {
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + result.data.token;
          return axios.get("/api/users/me").then((result) => {
            setUser(result.data);
            setIsAuthenticating(false);
          });
        });
      });
    });
  };

  const signup = (email, password) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((response) => {
        setCredential(response.user);
        return response.user.getIdToken().then((idToken) => {
          return axios.post("/api/auth/signup", {'idToken': idToken}).then((result) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + result.data.token;
            return axios.get("/api/users/me").then((result) => {
              setUser(result.data);
            });
          });
        });
      });
  };

  //TO ADD: signInWithEmailLink,

  const logout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setCredential(null);
        setUser(null); //changed from false
      });
  };

  const sendPasswordResetEmail = (email) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if(user){
        return user.getIdToken().then((idToken) => {
          return axios.post("/api/auth/tokenLogin", {'idToken': idToken}).then((result) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + result.data.token;
            return axios.get("/api/users/me").then((result) => {
              setCredential(user);
              setUser(result.data);
              setIsAuthenticating(false);
            });
          }).catch(function(){
            firebase.auth().signOut().then(() => {
              setCredential(null);
              setUser(null); //changed from false
            });
          });
        });
      }else{
        setCredential(null);
        setUser(null);
        setIsAuthenticating(false);
      }

    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);


  

  // The user object and auth methods
  const values = {
    updateUser,
    user,
    credential,
    isAuthenticating,
    login,
    loginWithGoogle,
    loginWithEmailLink,
    signup,
    logout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    linkEmailPassword,
    changePassword,
    setPref,
    getPref,
    getAllPref
  };

  // Provider component that wraps your app and makes auth object
  // ... available to any child component that calls useAuth().
  return (
    <AuthContext.Provider value={values}>
      {!isAuthenticating && children}
    </AuthContext.Provider>
  );
};