import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from '../../components/util-components/Loading';

export const AppViews = () => {
  console.log("AppViews");
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route path={`/dashboard`} component={lazy(() => import(`./dashboard`))} />
        <Route path={`/brands`} component={lazy(() => import(`./brands`))} />
        <Route path={`/brand/:id`} component={lazy(() => import(`./brand`))} />
        <Route path={`/list/:id`} component={lazy(() => import(`./list`))} />
        <Route path={`/emails`} component={lazy(() => import(`./emails`))} />
        <Route path={`/lists`} component={lazy(() => import(`./lists`))} />
        <Route path={`/suggest`} component={lazy(() => import(`./suggest`))} />
        <Route path={`/projectEmail/:id`} component={lazy(() => import(`./project-email`))} />
        <Route path={`/project/:id`} component={lazy(() => import(`./project`))} />
        <Route path={`/projects`} component={lazy(() => import(`./projects`))}/>    
        <Route path={`/collections`} component={lazy(() => import(`./collections`))} />
        <Route path={`/collection/email/:id`} component={lazy(() => import(`./collectionEmail`))} />
        <Route path={`/collection/:id`} component={lazy(() => import(`./collection`))} />
        <Route path={`/suggest`} component={lazy(() => import(`./suggest`))} />
        <Route path={`/settings`} component={lazy(() => import(`./settings`))} />
        <Route path={`/manager`} component={lazy(() => import(`./manager`))} />
        <Route path={`/matcher`} component={lazy(() => import(`./matcher`))} />
        <Route path={`/work`} component={lazy(() => import(`./work`))} />
        <Route path={`/requested`} component={lazy(() => import(`./requested`))} />
        <Route path={`/custom`} component={lazy(() => import(`./custom`))} />
        <Route path={`/tracking`} component={lazy(() => import(`./tracking`))} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);